import React from "react";
import ProjectsMenu from "./ProjectsMenu";

const Projects = () => {
  return (
    <>
      <ProjectsMenu />
    </>
  );
};

export default Projects;
