import React from 'react'
import Avatar from "../avatar/Avatar.js";
import AboutMenu from "./AboutMenu.js"

const About = () => {
  return (
    <>
      <Avatar page="about"/>
      <AboutMenu/>
    </>
  )
}

export default About